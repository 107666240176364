<template>
   <div class="log">
     <div class="logcont">
       <div class="dllogo"><img src="../../assets/images/dllogo.png" @click="$router.push({path:'/'})" style="cursor: pointer;"></div>
       <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
         <el-form-item label="请输入用户名" prop="username">
            <el-input v-model="loginForm.username" type="text" auto-complete="off"></el-input>
         </el-form-item>
         <el-form-item label="请输入密码" prop="password">
            <el-input  v-model="loginForm.password" type="password" auto-complete="off" @keyup.enter.native="handleLogin"></el-input>
         </el-form-item>
         <el-button @click.native.prevent="handleLogin" :loading="loading">登录</el-button>
<!--         <div class="acco">
           <p>还没有账号？ 去<span @click="register">注册</span></p>
           <p><span @click="forgetPass">忘记密码</span></p>
         </div>-->
       </el-form>
       <!--微信登录-->
       <div class="wechat">
           <a href="javascript:void(0);" @click="toOpenPlatformLoginUrl">
             <img src="../../assets/images/weixin.png">
             <span>微信登录</span>
           </a>
       </div>
     </div>
   </div>
</template>

<script>
import { getOpenPlatformLoginUrl} from "@/api/login"
export default {
  name: "Login",
  data(){
    return{
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" }
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" }
        ]
      },
      loading: false,
      redirect: undefined
    }
  },
  created () {

  },
  methods:{
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch("Login", this.loginForm).then(() => {

            let path=this.$route.params.path;
            let query=this.$route.params.query;
            if(!path && !query){
              path='/';
              query={};
            }
            this.$router.push({ path: path,query:query}).catch(()=>{});
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    },
    handleLogout(){
      this.$store.dispatch("LogOut").then(() => {
            this.$router.push({ path: "/" }).catch(()=>{});
      }).catch(() => {
            this.loading = false;
      });
    },
    register(){
      this.$router.push({
        name:'register',
      })
    },
    forgetPass(){
      this.$router.push({
        path:'/forgetPass',
      })
    },
    toWeixinLogin(){
      location.href='/api/public/weixinLogin';
    },
    toOpenPlatformLoginUrl(){
      getOpenPlatformLoginUrl().then((res)=>{
        if(res.code==200){
          window.location.href=res.url;
        }
      })
    }
  }
}
</script>

<style scoped>
.log{
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/dlbg.png");
  background-size: 100% 100%;
}
.logcont{
  width: 500px;
  height: 462px;
  background: rgba(255,255,255,.85);
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  position: relative;
  left: 50%;
  margin-left: -250px;
  top: 180px;
  padding: 40px;
  box-sizing: border-box;
}
.el-form{
  padding-top: 40px;
}
.el-form >>> .el-form-item__label{
  color: #333333;
}
.el-form >>> .el-input.is-active .el-input__inner,.el-form >>> .el-input__inner:focus,.el-form >>> .el-button:active{
  border-color:transparent;
}
.el-form >>> .el-button{
  width: 100%;
  background: #FF5252;
  margin-top: 10px;
  color: #FFFFFF;
  height: 50px;
}
.el-form >>> .el-form-item__label{
  line-height: inherit;
  padding-bottom: 10px;
}
.el-form >>> .el-form-item{
  margin-bottom: 20px;
}
.el-form >>> .el-input__inner{
  height: 50px;
}
.acco{
  color: #999999;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  font-size: 14px;
}
.acco span{
  color: #FF5252;
  cursor: pointer;
}

.wechat a{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-decoration: none;
}
.wechat img{
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.wechat a span{
  font-size: 14px;
  color: #333333;
}
</style>
